.BookingFailed {
    border-bottom: 3px solid #D8E6F8;
    padding:30px;
    display:flex;
    justify-content: center;
    margin-bottom: 100px;
}

.BookingFailed .buttons{
    display:block;
}

.BookingFailed_Text button {
    float:left;
}

.BookingFailed_Text {
    
}

.BookingFailed h1 {
    color: #FF0000;
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
}

.BookingFailed h2 {
    color: #FF0000;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}