.footer-container {
  background-color: white;
  padding: 2rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container .footer1 {
  max-width: 1440px;
  padding: 0 7rem 0 7rem;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  text-align: left;
  /* width: 160px; */
  box-sizing: border-box;
  color: rgba(0,0,0,0.6);
  font-family: "Gotham Rounded";
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
}

.footer-menu-items a{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  /* width: 160px; */
  box-sizing: border-box;
  color: rgba(0,0,0,0.6);
  font-family: "Gotham Rounded";
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-menu-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-link-items .powered{
  max-width: 250px;
  margin-top: 6px;
  position: relative;
}

.footer-link-items .footer-menu h2 {
  margin-top: 16px;
}

.footer-link-items .footer-menu > h2 {
  color: #003A81;
  font-family: "Gotham Rounded";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
}

.footer-link-items .footer-menu p {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.footer-link-items p img {
  margin: 6px 12px 6px 0;
  width: auto;
  height: 42px;
}

.footer-link-items .footer-menu a {
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items .footer-menu a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.footer-copyright-container {
  width: 100%;
  /* max-width: 1440px; */
  background-color: #003A81;
  display:flex;
  padding: 0 30px 0 30px;
  margin-top: 20px;
  border-top: 9px solid #ffba00;
}

.copyright-handal {
  color: white;
  font-family: "Gotham Rounded";
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  text-align: center;
  margin-left: 35px;
}

.copyright-handal-link {
  color: #FFFFFF;
  font-family: "Gotham Rounded";
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  padding:6px;
  margin-right: 10px;
  text-align: left;
}

.footer-icon {
  margin-left:10px;
  text-align: center;
}

.footer-icon-center {
  justify-content: center;
  display:flex;
}

.footer-icon-center i{
  color: #fff;
  font-size: 12px;
  display: block;
  width: 22px;
  line-height: 20px;
  border: 1px #fff solid;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: .3s ease;
}

.footer-icon-center i:hover{
  color: #003A81;
  background-color: rgb(255, 255, 255);
}

.middle {
  align-self: center;
}

.footer-subcontainer {
  width: 100%;
  max-width: 1440px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.line {
  width: 1px; 
  height: 100%; 
  color: #D8D8D8;
  border: 1px solid;
  border-radius: 3px;
  opacity: 0.3;
  display: inline;
  margin-left: 45px;
}

.border-left {
  border-left: 1px solid #D8D8D8;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.footer-menu span {
  display:none;
}

@media (max-width: 992px) {
  .border-left {
    border:none;
    margin:0;
    text-align: center; 
  }

  .copyright-handal {
    margin:0;
  }
}
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .footer-link-items {
    align-items: left;
    width: 100%;
  }

  .footer-menu span{
    display:block;
  }
  
  .footer-menu {
    cursor: pointer;
    width: 90%;
  }

  .footer-menu-items {
    display:none;
  }

  .active {
    display:block;
  }

  .arrow_turn {
    transform: rotate(180deg);
    transition: .3s ease;
  }
  .footer-link-items .footer-menu h2 {
    margin:0;
    border-top:1px solid #eee;
    padding: 10px 0 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .footer-container .footer1 {
    padding: 0 0 0 15px;
  }

  .footer-images h2 {
    margin-top:10px!important;
  }

}
