.signUp {
    height: 600px;
    width: 100%;
    background: url("../../public/images/img_slider_sign_up.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    display: grid;
    align-content: flex-end;
    padding-bottom:78px;
    justify-items: center;
}

.signUp .content {
    position: absolute;
    bottom: 0;
    margin-left:25%;
    margin-right:25%;

}

.signUp .text {
    text-align: left;
}

.signUp h1 {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    display:inherit;
    /* padding-bottom: 40%; */
    /* height: 60%;
    vertical-align: bottom; */
}

.signUp img {
    width: 15px;
    height: 15px;
    display: inline-block;
}

.signUp ul {
    list-style:none;
    padding-left: 0;
}

.signUp li {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
}

.height-600 {
    height: 600px;
}

.login {
    height: 100%;
    display: grid;
    align-content: flex-end;
    padding-right:20px;
}

.login h1 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
}

.login h2 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
}

.login h3 {
    color: rgba(0,0,0,0.5);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.login h4 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    float: right;
}

.login h5 {
    padding-top:20px;
    padding-bottom:20px;
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
}

.login h6 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.login .close {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
}

.login .login_modal_footer {
    padding-top: 80px;
    display:block;
    text-align: center;
}

.login .signUp_modal_footer {
    padding-top:85px;
    padding-bottom:35px;
    display:block;
    text-align: center;
}

.login .login_modal_footer2 {
    display:block;
    text-align: center;
}

.login_modal_body input[type="text"], input[type="password"] {
    /* margin: 0 20px 20px 0px; */
    height: 50px;
    width: 100%;
    border: 1px solid #003A81;
    border-radius: 5px;
    background-color: #FFFFFF;
    text-indent: 10px!important;
}

.login_modal_body input[type="text"]::placeholder, input[type="password"]::placeholder {
    text-indent: 10px!important;
    padding:0;
    color: rgba(0,0,0,0.4);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}

.login_modal_body p {
    text-align: center;
    margin:0;
    font-size: 20px;
}

.login_button {
    border-radius: 25.5px!important;
    color: white!important;
    background-color: #FFBA00!important;
    border:none;
    height: 51px;
    width: 80%;
}

.extra_margin {
    /* margin-top: 115px; */
}

.one_step_closer {
    display:block;
}

.modal_header_2 {
    display: grid;
}

.signUp_button {
    border-radius: 25.5px!important;
    border: none;
    background-color: #0068B2!important;
    color: #fff!important;
    height: 51px;
    width: 80%;
    
}

.signUp_padding {
    padding-bottom: 40px;
}

.modal-90w {
    width: 90%;
    min-width: 90%;
}

.width50 {
    width: 50% !important;
}

.inlineBlock {
    display: inline-block;
}

.forgot_password_button {
    cursor: pointer;
}

@media (min-width:1400px) {
    .modal-90w {
        min-width: 1370px;
    }
}

@media (max-width:974px) {
    .login_modal_body p {
        font-size: 12px;
    }
}

@media (max-width:575px) {
    .login_modal_body p {
        font-size: 16px;
    }
}