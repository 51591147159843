.confirmationEmail {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    padding-bottom: 10px;
    margin-top: 30px;
}

.confirmationEmail_title {
    height: 83px;
    width: 100%;
    border-radius: 3px 3px 0 0;
    background: linear-gradient(90deg, #006CB7 0%, #003A81 100%);
  
}

.confirmationEmail_title h1{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: left;
    padding: 10px 0px 0px 30px;
}

.confirmationEmail_title h2{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    padding-left: 30px;
}

.confirmationEmail_content img {
    padding: 20px;
}

.confirmationEmail_content h1 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    padding-top: 30px;
}

.confirmationEmail_content h2 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}

.confirmationEmail_content h3 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    padding-left: 30px;
}

.confirmationEmail_content input{
    margin: 0 20px 10px 30px;
    width: 90%!important;
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    text-indent: 8px!important;
    font-size:14px;
}

.confirmationEmail_content input::placeholder {
    text-indent: 8px!important;
    padding:0;
    color: rgba(0,0,0,0.2);
    font-weight: 100;
}