.proceedBusFare_container {
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border:1px solid #E7E7E7;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
}

.proceedBusFare_container h1{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    padding: 20px 20px 0 20px;
}

.secure_text {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
}

.tnc_text {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    padding:20px;
}

.tnc_text a {
    color: #0068B2!important;
    display: inline-block!important;
    text-decoration: none;
    padding:0!important;
    margin:0!important;
}

.bus_fare_depart {
    padding-bottom: 30px;
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;
}

.bus_fare_return {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;
}

.bus_fare_add_on {
    padding-top: 30px;
}

.bus_fare_add_on h2 {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.add-On-container {
    width:35%;
    height:40px;
    margin-left:-21px;
    background-color: #003A81;
    position:relative;
    display: inline-block;
  }

.arrow-container {
    width:45%;
    height:40px;
    background-color: rgba(0,58,129,0.1);
    position:relative;
    display: inline-block;
  }
      
.arrow-add-on {
    width: 0; 
    height: 0; 
    right:0;
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; /* 40px height (20+20) */
    border-right: 20px solid rgba(255, 255, 255);
    position:absolute;
    top:0;
}
      
.arrow-right {
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; /* 40px height (20+20) */
    border-left: 20px solid rgba(0,58,129,0.1);
    position:absolute;
    z-index: 3;
    right:-20px;
    top:0;
}
      
.arrow-right-front {
    width: 0; 
    height: 0; 
    left:0;
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; /* 40px height (20+20) */
    border-left: 20px solid rgba(255, 255, 255);
    position:absolute;
    top:0;
    /* right:-20px; */
}

.ms-left {
    margin-left: 0.5rem;
}

.text-in-line {
    display: table;
    margin: auto;
    margin-top: 10px;
	padding-left: 6px;
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
}

.proceedBusFare_container h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
    padding: 10px 20px 0px 25px;
}

.proceedBusFare_container h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
    padding: 8px 50px 0px 20px;
}

.proceedBusFare_container a {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    display:block;
    padding-left: 20px;
    margin-top: 20px;
}

.selectSeatError {
    border-radius: 4px;
    background-color: #FFDDDD;
    height: 35px;
    width: 100%;
    color: #FF0000;
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
}

.selectSeatError h5{
    color: #FF0000;
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    vertical-align: middle;
    /* text-align: end; */
	text-align: left;
    padding:7px 20px 10px 0px;
}

.add-on-table h4{
    padding: 0px 50px 0px 30px;
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}

.add-on-table img{
    float:right;
    padding-right: 50px;
}

.bus-fare-add-on {
    border-bottom:1px solid #E7E7E7;
    padding-bottom: 20px;
}

.bus-fare-total {
    float:right;
    padding-right: 30px;
}

.bus-fare-total h3{
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
}