.passenger_details {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    margin-bottom: 20px;  
}

.passenger_form {
    padding: 10px 0 20px 0px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);  
}

.passenger_form .text-line {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ddd;
    line-height: 0.1em;
    margin: 20px 0 7px;
    
}

.passenger_form span {
    display: inline;
    color: #556E8C;
    background-color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 0px 20px;
    width: 100%;
}

.passenger_form .ticketCenter {
    align-self: center;
}

.passenger_form .ticketType {
    margin-left:0;
    width:50%;
}

.passenger_form select {
    height: 35px;
    width: 90%;
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 0px 27px 0px 20px;
    margin-left: 20px;
    color: rgba(0,0,0,1);
    font-family: "Gotham Rounded";
    /* font-weight: bold; */
    letter-spacing: 0;
    line-height: 14px;
}

.passenger_form .first-data {
    color: rgba(0,0,0,0.2);
}

.passenger_form .first-data option {
    color: rgba(0,0,0,1);
}

.passenger_form .react-datepicker__navigation {
    height: 44px;
    width: 0px;
    overflow: initial;
}

.passenger_form .react-datepicker__navigation-icon {
    background-color: transparent;
}

.passenger_form .Date_Picker{
    height: 35px;
    margin: 0!important;
    /* text-align: center; */
    /* text-indent: 16px!important; */
}

.passenger_form .Date_Picker select{
    /* height: 35px!important; */
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 0px;
    margin-left: 20px;
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}

.passenger_form .react-datepicker__header__dropdown select {
    padding: 0px;
}

.passenger_form label {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    padding: 0px 0 0 20px;
}

.passenger_form input[type="text"] {
    margin: 0 20px 0px 20px;
    width: 90%;
    border: 1px solid #003A81;
    border-radius: 3px;
    text-indent: 8px;
    padding-left: 13px;
}

.passenger_form .fullname {
    width: 95%!important;
}

.passenger_form input[type="text"]::placeholder {
    text-indent: 8px!important;
    padding:0;
    color: rgba(0,0,0,0.2)!important;
    font-weight: 100;
}

.passenger_form input[type="text"]:disabled {
    background-color:#e9ecef;
    opacity: 1;
    color:rgba(0,0,0,0.6);
}

.passenger_form .row {
    margin-top:10px
}

.passenger_form .PassengerSeats{
    display:block;
    color:#b2b2b2;
    font-size:14px;
    font-weight: 100;
}

.passenger_form input[type="text"]::placeholder {
    color: rgba(0,0,0,0.3)
}

.passenger_form input[type="text"]::placeholder, .passenger_form select{
    font-size: 14px;
}

.passenger_form input[type="text"], .passenger_form select{
    font-size: 14px;
}

.passenger_details_title {
    height: 83px;
    width: 100%;
    border-radius: 3px 3px 0 0;
    background: linear-gradient(90deg, #006CB7 0%, #003A81 100%);
  
}

.passenger_details_title h1{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: left;
    padding: 10px 0px 0px 30px;
}

.passenger_details_title h2{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    padding-left: 30px;
}

.passenger_form h6 {
    margin-left:20px;
}

@media screen and (max-width: 575px) {
    .passenger_form .ticketType {
        margin-left:0px;
        width:87%;
    }

    .passenger_form .fullname {
        width: 90%!important;
        margin-top: 100px;
    }

    .passenger_form input[type="text"] {
        margin: 0 20px 10px 20px!important;
    }

    .passenger_form span {
        font-size: 12px;
    }

    .passenger_form .Date_Picker{
        text-align: left;
        /* text-indent: 20px!important; */
    }
}

@media screen and (max-width: 414px) {
    .passenger_form .ticketType {
        width:90%;
    }
}