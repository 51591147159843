:root {
    --primary: transparent;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424!important;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: #003A81!important;
    color:#fff!important;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 3px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large--curve {
    border-radius: 25px;
    background: #003A81!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1)!important;
    font-size: 14px;
    height: 50px;
    width: 200px;
    border: 1.5px solid #003A81!important;
}

.btn--medium:hover, .btn--large:hover, .btn--large--curve:hover {
    background: rgb(32, 97, 236)!important;
    color: #242424!important;
    transition: 250ms;
}

.btn--full {
    width: 100%;
    height: 100%;
}

.btn--full--curve {
    width: 100%;
    border-radius: 25px;
    background: #003A81!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1)!important;
    font-size: 14px;
    height: 50px;
    border: 1.5px solid #003A81!important;
}

.btn--find--more {
    border-radius: 25px!important;
    background: #ffffff!important;
    color:#003A81!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    font-size: 14px;
    font-weight: 500;
    border: 1.5px solid #003A81!important; 
}

.btn--find--more:hover {
    background: rgb(32, 97, 236)!important;
    color: #242424!important;
    transition: 250ms;
}

.btn--change--trip {
    border-radius: 20px!important;
    color: #ffffff!important;
    width:147px;
    background:#0068B2!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    font-size: 14px;
    font-weight: 500;
    border: 1.5px solid #003A81!important;   
}

.btn--large--rectangle {
    background: #003A81!important;
    color: white!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    font-size: 14px;
    height: 50px;
    width: 45%;
    border: 1.5px solid #003A81;
    margin: 5px;
}

.btn--print--ticket {
    color: white!important;
    border-radius: 20px!important;
    background-color: #FFBA00!important;
}

.btn--print--ticket--rectangle {
    color: white!important;
    background-color: #FFBA00!important;
}

.btn--ticket {
    height: 40px;
    width: auto;
    padding: 8px 20px!important;
    float:right;
}

.btn--print--ticket--full--width {
    color: white!important;
    border-radius: 20px!important;
    background-color: #FFBA00!important;
    width: 100%!important;
    height:50px;
    border:none;
}

.btn--yellow--white--full--width {
    border: 2px solid #FFC100!important;
    color: #FFBA00!important;
    border-radius: 20px!important;
    background-color: #FFFFFF!important;
    width: 100%!important;
    height:50px;
}

.font-children {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.btn--logIn {
    border-radius: 25.5px!important;
    color: white!important;
    background-color: #FFBA00!important;
}

.btn--signUp {
    border-radius: 25.5px!important;
    background-color: #0068B2!important;
    color: #fff!important;
}

.btn--signUp:hover {
    border-color: #0068B2!important;
}

.btn--80w {
    height: 51px;
    width: 80%;
}

.buttonConfirm {
    display: block!important;
    /* padding:20px; */
}