video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.Header-container {
  background: url('/public/images/img_slider.png') center center/cover no-repeat;
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.text-box {
  width: 447px;
  max-width: 100%;
  height: 216px;
  text-align: right;
}

.text-box > h1 {
  height: 80px;
  width: 447px;
  max-width: 100%;
  color: #FFFFFF;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 96px;
}

.text-box > p {
  height: 24px;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: right;
}

.text-box > p.left {
  text-align: left;
}

.text-box > p.right {
  text-align: right;
}

.Header-btns {
  width: 100%;
}

.fa-play-circle {
  margin-left: 4px;
}

.booking-container {
  display: -webkit-box;
  bottom: 0;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  padding:30px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -100px;
}

.booking-container h1 {
  color:#003A81;
  font-family: 'Nunito, san-serif';
}

.form, .full-width{
  width: 100%;
}

.margin_top_10px {
  margin-top:10px!important;
}

.pt-22px {
  padding-top: 22px;
  line-height: 0;
}

.item1 { }
.item2 { width: 100%;  height: 50px; border: 1px solid #003a81;}
.item3 { width: 100%; height: 50px; border: 1px solid #003a81;}
.item4 { width: 100%; height: 50px; border: 1px solid #003a81;}
.item5 { height: 50px;}

.item3 input[type="text"] {
  text-indent: 57px;
}

.item3 input[type="text"]::placeholder {
  text-indent: 37px;
}
.border-none {
  border: none;
}

.box-size {
  height: 100%;
}
.form-grid {
  display:grid;
  gap:20px;
  width: 100%;
}
.form-grid > h1{
  /* background-color: black; */
}

.title {
  text-align-last: left;
}

.half-width {
  width:50%;
}

::placeholder {
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
}

.icon-switch{
  position:absolute;
  margin-left: -18px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #fff;
}

.icon-calendar {
  position:absolute;
  margin-top: 15px;
  margin-left: 15px;
}

.checkOneWay {
  display:flex;
  position:absolute;
  margin-left: -273px;
  width: 282px;
  background-color: #003b81;
  align-items: center;
  height:50px;
  z-index: 999;
  margin-top: -1px;
}

.checkOneWay input{
  /* padding-left: 5px; */
  margin-left:-10px!important;
}

.checkOneWay input:checked{
  background-color: #ffca74;
  border: 2px solid #ffca74;
}

.checkOneWay label{
  position: relative;
  color: #fff;
  display: block;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 48px; */
  /* padding-left: 5px; */
  text-decoration: none;
  padding-left:25px !important;
  margin-left:-10px!important;
  cursor: pointer;
}

.checkOneWay .twoway {
  margin-left:110px;
}

.calendar {
  position:absolute;
  /* display:none; */
  z-index: 999;
  margin-left: -273px;
}

.calendar .react-calendar__year-view button, .calendar .react-calendar__decade-view button, .calendar .react-calendar__century-view button{
  padding: 23px 0.5em;
}
.calendar_button {
  position:absolute;
  background-color: #003A81;
  color:white;
  width: 700px;
  margin-top:307px;
  margin-left: -273px;
  z-index:999;
  max-width: 100%;
  height: 40px;
}

.border-right {
  border-right: 1px solid #003a81;
}

input:focus{
  outline: none;
}

.position-adjust {
  text-align:end;
}

.text-indent {
  text-indent: 30px;
}

input[type="date"] {
  text-indent: 30px;
}

input[type="text"], input[type="number"] {
  text-indent: 30px;
}

input[type="text"]::placeholder, input[type="number"]::placeholder {                 
  text-indent: 10px;
}

.item2 label, .item4 label {
  color: #b2b2b2;
  font-size: 12px;
  font-weight: 600;
  padding-left: 30px;
  position:absolute;
}

.item3 label {
  color: #b2b2b2;
  font-size: 12px;
  font-weight: 600;
  padding-left: 60px;
  position:absolute;
}

.checkOneWay label {
  font-size: 14px;
}

.origin-box {
  padding: 22px 22px 0px 30px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}

.origin-box:after {
    /* border-color: transparent transparent #fff transparent; */
    border:none;
    top: 7px;
}
@media screen and (max-width:1075px){
  .calendar {
    width: 674px!important;
  }
  .calendar_button {
    width: 674px;
  }
}

@media screen and (max-width:1000px){
  .calendar {
    width: 643px!important;
  }
  .calendar_button {
    width: 643px;
  }
  .calendar .react-calendar__year-view button, .calendar .react-calendar__decade-view button, .calendar .react-calendar__century-view button{
    font-size: 12px;
  }
}

@media screen and (max-width: 960px) {
  .Header-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width:928px){
  .calendar {
    width: 613px!important;
  }
  .calendar_button {
    width: 613px;
  }
}

@media screen and (max-width:855px){
  .calendar {
    width: 582px!important;
  }
  .calendar_button {
    width: 582px;
  }
}

@media screen and (max-width: 768px) {
  .Header-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .Header-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 90%;
    margin: 0px!important;
  }

  .calendar {
    width: 350px!important;
    margin-left:0;
    margin-top:50px;
  }
  .calendar_button {
    width: 350px;
    margin-left:0;
    margin-top:321px;
  }
  .checkOneWay {
    display:flex;
    width: 350px;
    max-width: 100%;
    margin-top: 48px;
    margin-left:0;
  }

  .checkOneWay .twoway{
    margin-left:180px;
  }

  .calendar .react-calendar__year-view button, .calendar .react-calendar__decade-view button, .calendar .react-calendar__century-view button{
    padding: 19px 0.5em;
  }
}

@media screen and (max-width: 575px) {
  .text-box > h1 {
    font-size: 50px;
  }

  .text-box {
    padding: 0 20px 0 20px;
  }

  .item2 label, .item4 label {
    font-size: 10px;
  }
  
  .item3 label {
    font-size: 10px;
  }

  .checkOneWay label {
    font-size: 14px;
  }
}

@media screen and (max-width: 338px) {
  .checkOneWay .twoway{
    margin-left:100px;
  }
}