.navbar {
  height: 70px;
  width: 100%;
  background: linear-gradient(135.99deg, #006CB7 0%, #003A81 100%);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position:inherit;
  top: 0;
  z-index: 999;
  padding: 0px!important;
  flex-wrap: nowrap!important;
}

.navbar-container {
  display: flex;
  flex-wrap: wrap!important;
  justify-content: flex-end!important;
  align-items: center;
  height: 80px;
}


.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-container div.nav2 {
    float:right;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  justify-content: flex-end!important;
  margin-right: 2rem;
  align-items: center;
}

.nav1 {
    float:left;
}

.nav2 {
    float:right;
}

.nav-item {
  height: 65px!important;
}

.nav-item .lang{
  text-align: center;
}

.nav_collapse a {
  text-decoration: none;
}

.nav-links {
  color: #fff!important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
  --bs-nav-link-color: #fff!important;
  --bs-nav-link-hover-color: #f5c014!important;
}

.nav-links:hover {
    color: #f5c014!important;
    --bs-nav-link-color: #f5c014!important;
  /* border-bottom: 4px solid #fff; */
}

select option:hover, 
select option:focus, 
select option:active, 
select option:checked,
.nav-links option {
  background: linear-gradient(135.99deg, #006CB7 0%, #003A81 100%);
  border: none;
  outline-offset:unset;
  font-size: 14px;
  height:20px;
  color: #f5c014;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.menu-logo {
  top:5px;
  left:25px;
  position: absolute;
  height: auto;
  width: 236px;
  text-align: center;
}

.menu-logo-container {
  position: relative;
}

.menu-header {
  position: relative;
  top: 0;
  left: 0;
}

.menu-express {
    color: rgb(28, 28, 136);
    height: 48px;
    width: 248px;
    font-size: 15px;
    overflow: visible;
}

.menu-cwl {
    color:rgb(28, 28, 136);
    font-family: fantasy;
    font-style: italic;
    padding:15px;
}

.menu-back-rectangle {
    height: 77px;
    width: 350.24px;
    border-radius: 0 0 100px 0;
    background-color: rgba(255,255,0,0.3);
}

.log-in {
  display: none!important;
}

.right {
  float:right;
}

@media screen and (max-width: 991px) {
  
  .nav_collapse {
    width: 100%;
    position: absolute;
    top: 0px;
    margin-top: 70px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-link {
    padding:0;
  }

  .nav-links {
    background-color: black!important;
    text-align: center;
    padding: 1rem 2rem 1rem 2rem;
    width: 100%;
    background: none;
    border: none;
    align-content: center;
    justify-content: center;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position:inherit;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }


}

@media screen and (max-width: 458px) {
  .menu-header {
    width: 180%;
  }

  .menu-logo {
    top:4px;
    width: 200px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-container {
    align-items: flex-end!important;
    flex-direction: column;
  }
  
}

@media screen and (min-width: 768px) {
  .navbar-container {
    max-width: 720px!important;
  }
    
}