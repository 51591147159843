.destination-container {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.destination-img-container {
    background: url('../../public/images/img_penang.png');
    margin-left: auto;
    margin-right: auto;
    height: 450px;
    max-width: 1440px;
}

.destination-above {
    height: 181px;
    max-width: 1440px;
    background-color: rgba(0,0,0,0.6);
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
}

.new-destination {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    padding: 30px;
}

.new-destination-description {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    padding-bottom: 20px;
}

.new-destination-label {
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding-bottom: 20px;
  }

.explore {
    text-align: center;
    vertical-align: bottom;
    padding-top: 170px;
}

.travel-container {
    height:314px;
    width: 80%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    display:grid;
    grid-template-columns: repeat(5,auto);
    gap:20px;
}

.travel-find-more {
    padding: 50px;
}

.find-more {
    padding-top: 30px;
}

.travel-item__hr {
    height: 5px;
    width: 50px;
    border-radius: 1px;
    background-color: #003A81;
    margin: 0px;
}

.destination_hr {
    width: 2px; 
    height: 200px; 
    display: inline-block;
    margin-top: 50px;
    color: #E6E6E6;
    border: 1px solid;
    border-radius: 3px;
    opacity: 1;
}

.travel-item__text {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 12px;
    padding-bottom:10px;
}

.travel-item__title {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2px;
}

.travel-find-more h5 {
    display: initial;
}

.travel-item h5 {
    display: initial;
}

.travel-item__description {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
    padding-top: 15px;
}

@media screen and (max-width: 820px) {
    .travel-container {
        height:auto;
        width: 100%;
        grid-template-columns: none;
        grid-template-rows: repeat(3,auto);
        gap: 5px;
    }
    
    .travel-find-more {
        width: 100%;
        padding: 20px 50px 20px 50px;
    }

    .destination_hr {
        width: 50%; 
        height: 2px;
        display: inline-block;
        margin-top:0;
        margin-left:25% !important; 
        margin-right:25% !important;
        color: #E6E6E6;
    }
}