.icon-blue {
    color: #003A81;
}

.departure-header-container h3 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
}

.departure-header-container h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    line-height: 0.3;
    display: inline;
}

.departure-header-container h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
}

.departure-header-container {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: #FFFFFF;
    border-bottom: 3px solid #D8E6F8;
}

.float-right {
    float:right;
}

.departure-header-container a{
    cursor: pointer;
}

.departure-header-container input, .departure-header-container input::placeholder{
    text-indent: 5px;
    padding-left: 5px;
}

.departure-header-container .trip_type {
    font-size: 10px;
}

.departure-header-container .search_button {
    float:right;
    border-radius: 25px;
    color:white;
    background: #003A81!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1)!important;
    font-size: 14px;
    height: 30px;
    width: 100px;
    border: 1.5px solid #003A81!important;
}

.departure-header-container .edit_calendar {
    width: 40%;
}

.departure-header-container .edit_venue {
    width: 100%;
    height: 30px;
}

.departure-header-container hr {
    display:none;
}

.header_border-left {
    border-left: 1px solid #D8D8D8;
}

.vectorSwap {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .header_border-left {
        border: none;

    }

    .departure-header-container hr {
        display: block;
        margin-top:10px;
    }

    .center {
        text-align: center;
    }

    .float-right {
        margin-top:10px!important;
        float:none;
    }

    .search_button {
        margin-top:10px;
        float:none!important;
    }

    .none-992 {
        display:none;
    }
}