.proceed_departure_container {
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
}

.proceed_table_container {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
}

.proceed_table_container img {
    padding: 0 10px 7px 20px;
    display: inline-block;
}

.proceed_table_container h3 {
    /* padding: 0 10px 7px 20px; */
    color: white;
    font-family: "Gotham Rounded";
    font-weight: bold;
    /* vertical-align: middle; */
    line-height: 25px;
    text-align: right;
    font-size: 24px;
    padding: 25px;
	/* padding-top:10px; */
}

.proceed_table_container p {
    display: inline-block;
    padding: 10px;
}

/*
.flex_display {
    display:flex;
}
*/
.seatText_taken {
    text-align: right;
}

@media screen and (max-width: 576px) {
    .seatText_available {
        text-align: center;
    }

    .seatText_taken {
        text-align: center;
    }

    .proceed_table_container h1 {
        font-size: 12px;
        padding:25px 0 25px 0;
    }

    .proceed_table_container h3 {
        font-size: 12px;
        padding:25px 0 25px 0;
    }

    .proceed_table_container img {
        height:18px;
    }
}