.experience-background {
    background: linear-gradient(rgba(255, 255, 255, 0.9),rgba(0, 0, 0, 0.3)), url("../../public/images/homepage_coach2.png");
    height: 100%;
    width: 100%;
    background-size: auto 100%;
}

.text-box-item {
    padding: 80px;
}

.experience-four {
    width: 100%;
    height: 360px;
    background-color: #003A81;
    padding: 0px !important;
    color: white;
}

.p-0 {
    padding: 0px!important;
}

.border-1 {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.experience_item {
    width: 100%;
    text-align: center;
}


.experience_item__img {
    margin-top: 50px;
    width: 130px;
    height: auto;
    padding: 20px;
}

.experience_item__text {
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
  }

.experience_item a {
    color: #fff;
}