.bookingAddOnTitleContainer {
    border-radius: 3px 3px 0 0;
    line-height: 200px;
}

.bookingAddOnTitleContainer h1{
    text-align: left;
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 36px;
    font-weight: bold;
}

.bookingAddOnTitleContainer h5{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
}

.addOnCard {
    border: none;
    box-shadow: 0px 3px 4px 1px rgb(0 58 129 / 10%);
}

.extraCard {
    z-index: -1;
}

.blur {
    border-radius: 3px;
    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%);  
}

.categories {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}

.title {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}

.price {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}

.price span {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.maxWidth {
    max-width: 1440px;
}

.modal {
    overflow-y:auto;
  }

.addOn_img {
    height: 150px;
}