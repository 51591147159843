.SelectedTripSummary h2 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: bold;
}

.SelectedTripSummary h3 {
    color: #FFBA00;
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    text-align: right;
    padding-right: 20px;
}

.SelectedTripSummary h4 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
}

.SelectedTripSummary h5 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
}

.SelectedTripSummary h6 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
    padding-right: 20px;
}

.SelectedTripSummary .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #979797;
    background-color: #FFFFFF;
    display: inline-block;
}

.SelectedTripSummary .circle_destination {
    background-color: #A1A1A1;
    display: inline-block;
}

.SelectedTripSummary span {
    transform: rotate(90deg) translate(70%, 115%);
    display: inline-block;
    font-size: 20px;
    color: rgba(0,0,0,0.3);
}

.SelectedTripSummary a {
    cursor: pointer;
}

.buttonConfirm {
    display: block!important;
    padding:0 20px 20px 20px;
    font-weight: bold;
    font-size: 16px;
}