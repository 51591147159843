.pagination {
    display: flex;
    align-items: center;
    justify-content: center
  }
  
.pagination__button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 19px;
    color: #003A81;
    padding: 4px;
}

.pagination__button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.pagination__current-page {
    margin: 0 10px 0 15px;
    color: #888;
}

.pagination__total-pages {
    margin-right: 15px;
    color: #888;
}

.loyalty_pagination {
    border-bottom: none!important;
}

.loyalty_pagination td {
    padding: 30px 30px 20px 30px;
}