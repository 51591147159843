.flex_display {
    display: flex;
    flex-direction: column; /* Stack upper and lower decks */
    align-items: center; /* Center them horizontally */
	justify-content: flex-start;
}


.seat {
    text-align: end;
    /* transform: rotate(-90deg); */
    /* margin-top: -50%;
    margin-bottom: -50%; */
}

.seat h6 {
    font-size: 9px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
}

.seat_container {
    text-align: center;
    margin:auto;
    padding:20px;
    transform: rotate(-90deg);
    margin-top: -80px;
    margin-bottom: -80px;
    border:1px solid rgba(61, 61, 61, 0.25);
    border-top: 10px solid rgba(61, 61, 61, 0.75);
}

.double_seat {
    text-align: center;
}



.double_seat_container,
.double_seat_lower_container,
.double_seat_upper_container {
    text-align: center;
    margin: auto;
    padding: 20px;
    transform: rotate(-90deg);
    margin-bottom: -20px;
    border: 1px solid rgba(61, 61, 61, 0.25);
    border-top: 10px solid rgba(61, 61, 61, 0.75);
}

.double_seat_container {
    margin-top: -150px;
}
/*this is for yoyo, where there is no lower but upper seat*/
.double_seat_upper_container {
    margin-top: -90px;
}

.double_seat_lower_container {
    margin-top: -40px;
}

.lowerdeck_words {
    /* margin-bottom: 70px; */
}

.seat .corridor {
    width: 20px!important;
    height: 15px!important;
    cursor: initial!important;
    border: none!important;
    padding: 0!important;
    /* display: table-column; */
    display: block;
    visibility: hidden;
    transform: scale(0);
    z-index: -999999;
    opacity: 0;
}

.seat td {
    /* transform: rotate(-90deg); */
    padding:3px;
    /* width: 20px;
    height: 20px; */
    position: relative;
    text-align: center;
}

.seat .available {
    /* background-color: #FFFFFF; */
    color: black;
}

.seat .not_available {
    cursor: not-allowed;
    filter: invert(64%) sepia(0%) saturate(366%) hue-rotate(182deg) brightness(93%) contrast(94%);
}

.seat .selected {
    background-color: #0d6efd;
}

.seat .not_selected {
    background-color: #FFFFFF;
}

.seat a {
    text-align: center;
    color: black;
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    cursor: pointer;
    margin: auto;
    text-decoration: none;
}

.seat .seat_cell {
    /* border:2px solid black; */
    width:35px;
    height:35px;
    margin:auto;
    text-align: center;
    padding:1px;
}

.selected_seat_colour {
    /* filter: invert(24%) sepia(272%) saturate(6189%) hue-rotate(172deg) brightness(94%) contrast(102%) */
    /* filter: invert(14%) sepia(60%) saturate(3682%) hue-rotate(203deg) brightness(92%) contrast(101%); */
    /* filter: invert(18%) sepia(21%) saturate(6601%) hue-rotate(197deg) brightness(95%) contrast(108%); */
    filter: invert(19%) sepia(63%) saturate(6701%) hue-rotate(187deg) brightness(81%) contrast(225%)
}







@media screen and (max-width: 1400px) {

    /* .seat_container{
        transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } 

    .double_seat_container
    .double_seat_lower_container {

		transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } */
}

@media screen and (max-width: 1200px) {

    /* .seat_container{
        transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } 
	
	
    .double_seat_container
    .double_seat_lower_container {

		transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } */
}

@media screen and (max-width: 992px) {

    /* .seat_container{
        transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } 
	
	
    .double_seat_container
    .double_seat_lower_container {

		transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } */
}




@media screen and (max-width: 768px) {


    .seat_container{
        transform: rotate(0deg);
        margin-top: 25px;
        margin-bottom: 20px;
    } 

    .double_seat_container,
    .double_seat_lower_container,
	.double_seat_upper_container {

        /* margin-left: 60px; */
		margin-top: 0px;
		transform: rotate(0deg);
        margin-bottom: 20px;
    }


    .seat h6 {
        transform: translate(-50%, -50%) rotate(0deg);
    }

	
	.lowerdeck_words {
		margin-bottom: 0px;
	}

}