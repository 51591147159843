.newsLetter_container {
    max-width: 1440px;
    width: 100%;
    background-color: #F7F7F7;
    margin: 0px auto 0px auto;
}    

.newsLetter_content {
    text-align: center;
    padding: 50px;
}

.newsLetter_content .newsLetter_form {
    display:inline-block;
    padding: 12px;
    width: 49%;
}

.newsLetter_content .btn-newsLetter {
    display:inline-block;
    width: 49%;
}

.newsTitle {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;

}

.newsDescription {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
    padding: 13px;
    margin-bottom: 25px;
}

.textBox {
    box-sizing: border-box;
    height: 50px;
    width: 45%;
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    text-indent: 30px;
    margin: 5px;
}

@media (max-width:821px) {
    .textBox {
        width: 100%;
    }
}