.sign_in_to_collect_loyalty {
    width: 100%;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    height: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 0 20px 10px 20px;
}

.sign_in_to_collect_loyalty h3 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}

.sign_in_to_collect_loyalty h5 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}

.sign_in_to_collect_loyalty h2 {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}

.sign_in_to_collect_loyalty h4 {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}

.sign_in_to_collect_loyalty h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
}

.sign_in_to_collect_loyalty span {
    font-size: 18px;
}

.pt-2rem {
    padding-top: 2rem;
}

.sign_in_to_collect_loyalty .signIn_button {
    background-color: #003A81!important;
    color:#fff!important;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 3px;
    border-radius: 20px!important;
    color: #ffffff!important;
    width:147px;
    background:#0068B2!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    font-size: 14px;
    font-weight: 500;
    border: 1.5px solid #003A81!important;  
}