.acc_navbar {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    margin:0px 20px 20px 20px;
    text-align: center;
    position:sticky;
    top:15px;
}

.acc_navbar img{
    margin:auto;
}

.acc_navbar tr{
    max-width:100%;
    border-bottom: 1px solid #E7E7E7;
    cursor: pointer;
}

.acc_navbar td{
    padding:15px;
}

.acc_navbar h3 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    margin:auto;
    text-align: left;
}

.acc_details {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.AccDetails_Date_Picker{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s
}

.requiredStar {
    color:red;
}

.user_details h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
}

.user_details h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
}

.acc_details tr {
    border-bottom: 1px solid #E7E7E7;
}

.acc_details td {
    padding: 20px;
    overflow-wrap: anywhere;
}

.acc_details input[type='text']::placeholder {
    text-indent: 0;
    padding-left:0;
    color: rgba(0,0,0,0.3)
}

.user_details .password {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
}

.user_details .password{
    padding:20px;
}

.user_details .white_orange {
    border-radius: 20px;
    background-color: #FFBA00;
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    height: 40px;
    width: 147px;
    border:none;  
}

.user_details .f-right {
    float:right;
}

.user_details .emergency_contact {
    text-align: center;
    padding:20px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.user_details .edit_emergency_contact {
    padding:20px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.user_details .emergency_contact h4{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    padding:20px 20px 0px 20px;
}

.user_details .family_and_friends {
    text-align: center;
    padding:20px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.user_details .family_and_friends h4{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    padding:20px 20px 0px 20px;
}

.user_details .loyalty_program {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.user_details .loyalty_program tr{
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;
}

.user_details .loyalty_program td{
    padding-left: 20px;
    padding-right: 20px;
}

.user_details .loyalty_program h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    /* padding-bottom: 10px; */
}

.user_details .loyalty_program h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 10px;
}

.user_details .recent-transaction {
    background-color: #E4F3FB;
    color: #006CB7;
    padding: 20px;
    margin-bottom: 10px;
}

.user_details .total-points {
    padding: 0px 20px;
}

.user_details .loyalty_program .loyalty_button {
    height: 40px;
    width: 147px;
    border-radius: 20px;
    background-color: #0068B2;
    border: none;
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    
}

.user_details .loyalty_program .loyalty_button_border {
    border: none!important;
}

.user_details .loyalty_program .deduction {
    color: rgba(255,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
}

.user_details .loyalty_program .addition {
    color: rgba(20,158,64,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
}

.acc_details input[type='text'] {
    text-indent: 0;
}

.acc_details .edit_dob {
    display:inline-block;
    width: 31%;
}

.save_button {
    height: 40px;
    width: 147px;
    border-radius: 20px;
    background-color: #0068B2;
    border: none;
    color: #fff;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    float: right;
}

.acc_details a, .password a, .edit_emergency_contact a, .family_and_friends a{
    color: #0068B2!important;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
    cursor: pointer;
    /* float: right; */
}

.edit_emergency_contact input, .family_and_friends input {
    text-indent: 0;
}

.family_and_friends input::placeholder {
    text-indent: -20px;
}

.family_and_friends select {
    /* text-indent: 20px; */
}

.family_and_friends td {
    text-align: left;
}

.yellow {
    color:#FFBA00;
}

.clickable {
    cursor: pointer;
}

.accDetails_footer {
    display:inline-block;
    text-align: right;
    float:right;
}

.accDetails_footer div {
    margin-top:8px;
    margin-right: 8px;
    display:inline-block;
}






	/* General style for all half-circles */
	.half-circle {
		position: absolute;
		left: -26px;
		/* Align with left border */
		width: 30px;
		height: 15px;
		border-right: none;
		border-radius: 0px 20px 20px 0px;
		/* Half-circle shape */
		background-color: white;
		/* Matches the background */
	}

	/* Position the half-circles using nth-child */
	.half-circle:nth-child(1) {
		top: 5%;
	}

	.half-circle:nth-child(2) {
		top: 32%;
	}

	.half-circle:nth-child(3) {
		top: 55%;
	}

	.half-circle:nth-child(4) {
		top: 78%;
	}

	



@media screen and (max-width:992px) {
    .acc_navbar h3 {
        font-size: 13px;
    }
    .acc_details .edit_dob {
        width: 45%;
    }
}

@media screen and (max-width:487px) {
    .user_details .white_orange {
        font-size: 12px;
        width: 120px;
    }
    .acc_details .edit_dob {
        width: 100%;
    }
}

@media screen and (max-width:355px) {
    .user_details .white_orange {
        font-size: 10px;
        width: 100px;
    }
}
