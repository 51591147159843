@font-face {
  font-family: "gotham-rounded";   /*Can be any text*/
  src: local("gotham-rounded-medium"),
    url("./fonts/gotham-rounded-medium.otf") format("opentype");
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "gotham-rounded"!important;
  /* font-family: Montserrat, san-serif!important; */
}

.fas, .fa-solid, .fa {
  font-family:  'Font Awesome 6 Free' !important;
}

.fa-brands {
  font-family:  'Font Awesome 6 Brands' !important;
}

.text-left {
  text-align: left;
}

.payment h1 {
  color: #003A81;
  font-family: "Gotham Rounded";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
  padding: 0px 10px 0 10px;
}

.payment h2 {
  color: #0074BF;
  font-family: "Gotham Rounded";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
  padding: 0px 10px 0 10px;
}

.payment span {
  transform: rotate(270deg);
}

.payment_total_fare {
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  padding: 20px;
}

.ResetPassword {
  background: linear-gradient(rgba(255, 255, 255, 0.9),rgba(0, 0, 0, 0.6)), url('../public/images/homepage-coach-exp-01.JPG');
  /* background: rgba(0, 0, 0,0.3); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100%;
  width: 100%;
  padding:100px;
}

.ResetPassword .password_box{
  border-radius: 20px;
  background-color: white;
  width: 500px;
  height: 400px;
  padding:20px;
  margin:auto;
  padding-top:30px;
}

.ResetPassword .password_box button{
  width:100%;
  text-align: center;
  border-radius: 20px;
  height:45px;
  border: none;
  background-color: #003A81;
  color: #fff;
  font-size: 20px;
  margin-top:10px;
}

.ResetPassword .password_box h5{
  font-size: 14px;
  color:red;
  
}


@media screen and (min-width: 576px) {
  .display-on-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 575px) {
  .hide-on-mobile {
    display: none !important;
  }
}