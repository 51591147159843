._title {
    height: 83px;
    width: 100%;
    border-radius: 3px 3px 0 0;
    background: linear-gradient(90deg, #006CB7 0%, #003A81 100%);
    padding: 10px 20px 10px 25px;
}

._title h1{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: left;
}

._title h2{
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
}

._title .yellow {
    color:#FFBA00;
}

._title .clickable {
    cursor: pointer;
}

._title .white_orange {
    border-radius: 20px;
    background-color: #FFBA00;
    color: #FFFFFF;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    height: 40px;
    width: 147px;
    border:none;  
}

._title .f-right {
    float:right;
    margin-right:30px;
}

@media screen and (max-width:487px) {
    ._title h1{
        font-size: 16px;
    }
    ._title h2{
        font-size: 8px;
        line-height: 10px;
    }
}

@media screen and (max-width:355px) {
    ._title h1{
        font-size: 13px;
    }
    ._title h2{
        font-size: 7px;
        line-height: 10px;
    }
}