.bus-fare-sub-total {
    width:100%;
    padding:10px;
    margin:30px 0 30px 0px;
    background-color: #F6F6F6;
}

.subTotal_table h3 {
    text-align: right;
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    padding-right: 10px;
}

.subTotal_table h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
}

.SubTotal_loyaltyDiscount h6{
    color: #0068B2!important;
    font-size: 12px!important;
    font-weight: 300!important;
    letter-spacing: 0!important;
    line-height: 14px!important;
    text-indent: 10px;
}

.SubTotal_loyaltyDiscount h3{
    color: #0068B2!important;
    font-size: 14px!important;
    font-weight: bold!important;
    letter-spacing: 0!important;
    line-height: 17px!important;
}