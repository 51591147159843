.addOn-container-border {
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;

}

.addOn-container-border tr{
    height:40px;
}

.addOn-container-border img{
    height: 180px;
    width: 100%;
}

.p-top-bottom {
    padding: 20px 0px 20px 0px;
}

.modal_header p {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0;
}

.modal_body h2 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.modal_body span {
    color: rgba(0,0,0,0.4);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: line-through;
    padding-right: 10px;
}

.modal_body_data div {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;
}

.modal_body input {
    width: 30px;
    text-indent: 0;
    text-align: center;
}

.quantity {
    padding: 0!important;
    background-color: #0068B2;
    text-align: center;
}

.quantity i {
    color: #F6F6F6;
}

.quantity a {
    cursor: pointer;
}

.btn_addToCart {
    background-color: #003A81!important;
    color:#fff!important;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 3px;
    width: 100%;
    border-radius: 25px;
    background: #003A81!important;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1)!important;
    font-size: 14px;
    height: 50px;
    border: 1.5px solid #003A81!important;
}

.bus_fare_total {
    border: none!important;
}

.bus_fare_total div{
    padding:0;
    border: none!important;
}

.addOnCard a {
    cursor: pointer;
}

.img-full-width {
    width: 100%;
}

.cursor_pointer {
    cursor: pointer;
}

iframe {
    height: 100%;
    width: 100%;
}

.addOnModalPopUp {
    height: 95%;
}

.addOnModalFooter {
    display:inline-block;
    text-align: right;
}

.addOnModalFooter div {
    margin-top:8px;
    margin-right: 8px;
    display:inline-block;
}

.addOnModalFooter a{
    cursor: pointer;
}