.booking_header {
    width: 100%;
    background-color: #FFFFFF;
    border-bottom: 3px solid #D8E6F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:20px;
}

.booking_header_status h6 {
    color: rgba(0,0,0,0.8);
    /* font-family: "Gotham Rounded"; */
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.font-ticket {
    color: #FFFFFF;
    /* font-family: "Gotham Rounded"; */
    font-size: 14px;
    font-weight: 500;
    text-align: center
}

.booking_header h5 {
    margin: 0 !important;
    line-height: 2 !important;
    color: rgba(0,0,0,0.6);
    /* font-family: "Gotham Rounded"; */
    font-size: 14px;
    font-weight: 300;

}

.booking_header span {
    color: rgba(0,0,0,0.8);
    /* font-family: "Gotham Rounded"; */
    font-size: 18px;
    font-weight: bold;
}

.status {
    color: rgba(0,0,0,0.8)!important;
    /* font-family: "Gotham Rounded"!important; */
    font-size: 18px!important;
    font-weight: bold!important;
    /* border-right: 2px solid #E6E6E6; */
    border-radius: 3px;
}

.no-border {
    border:none;
}

.booking-status {
    color: #13B157!important;
    /* font-family: "Gotham Rounded"!important; */
    font-size: 24px!important;
    font-weight: bold!important;
}


.booking-status-failed {
    color: #e30e0e!important;
    /* font-family: "Gotham Rounded"!important; */
    font-size: 24px!important;
    font-weight: bold!important;
}

.booking-status-pending {
    color: #FFBA00!important;
    /* font-family: "Gotham Rounded"!important; */
    font-size: 24px!important;
    font-weight: bold!important;
}


.flex-center {
    align-items: center;
}

.printTicket {
    color: white!important;
    border-radius: 20px!important;
    background-color: #FFBA00!important;
    height: 40px;
    width: auto;
    padding: 8px 20px!important;
    float:right;
    border:none;
}

.eInvoiceButton {
    color: white!important;
    border-radius: 20px!important;
    /* background-color: #FFBA00!important; */
	background-color: #707070 !important;
    height: 40px;
    width: auto;
    padding: 6px 20px 8px 20px !important;
    float:right;
    border:none;
}



.mx-1{
	margin-left: 5px;
	margin-right: 5px;
}

@media (max-width:991px) {
    .status {
        border-right: 0px;
    }
}