.cards {
  padding: 4rem 4rem 0 4rem;
  background: #fff;
}

.cards .originDropdown{
  background-color: #fff!important;
  color:#003A81;
  border:none;
}

.cards .originDropdownItem{
  display: block;
  width: 100%;
  font-size: 2.3rem;
  padding: 0px 15px 0px 15px;
  /* padding: var(--bs-originDropdownItem-padding-y) var(--bs-originDropdownItem-padding-x); */
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.cards .originDropdownItem:hover{
  background-color: grey;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  padding-left: 0;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}
/* 
.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
} */

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.header-left { grid-area: 'header-left'; }
.header-right { grid-area: 'header-right'; text-align: end }
.left { grid-area: left; }
.right { grid-area: right; text-align: end;}

.img-grid {
  display:grid;
  grid-template:
  'header-left header-right'
  'left right';
  column-gap:10px;
  width: 100%;
}

.cards__item__title {
  color: #003A81;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

.cards__item__price {
  color: black;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

.cards__item__text {
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
}


.cards span {
  color:#003A81;
}

.see-more-button {
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin: 0px 0px 2rem 0px;
  }
  .cards {
    padding: 4rem 0px 4rem 0px;
  }
}

@media only screen and (max-width: 768px) {
  .cards .originDropdownItem {
    font-size: 1.3rem;
  }
}
