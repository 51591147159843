.table-container h2 {
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 28px;
    font-weight: bold;
}

.table-container h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: bold;
    line-height: 0;
    display: inline;
}

.table-container h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
}

.table-container .center {
    text-align: center;
}

.details_container {
    border-radius: 5px;
    background-color: #FFF8E2;
    padding: 20px 0px 20px 0px;
}

.booking-border-left {
    border-left: 2px solid #E6E6E6;
    border-radius: 3px;
}

.ml-1 {
    margin-left:10px;
}

.mr-1 {
    margin-right: 10px;
}

.change_trip {
    border-radius: 20px!important;
    color: #ffffff!important;
    width: 147px;
    background: #0068B2!important;
    box-shadow: 0 3px 4px 1px rgb(0 58 129 / 10%);
    font-size: 14px;
    font-weight: bold;
    border: 1.5px solid #003A81!important;
    color: #fff!important;
    padding: 8px 20px;
    border-radius: 3px;
}



.booking-status {
    color: #13B157!important;
    /* font-family: "Gotham Rounded"!important; */
    font-size: 36px!important;
    font-weight: bold!important;
}



@media (max-width:575px) {
    .booking-border-left {
        border:none;
        margin:0;
      }

    .none {
        display:none;
    }
    .ml-1 {
        margin-left:0;
        margin-right:0;
    }
    .mr-1 {
        margin-right: 10px;
        margin-left:0;
    }
    .table-container h5 {
        font-size: 12px;
    }
}
