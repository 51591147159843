.selected_departure {
    border-radius: 5px;
    background-color: #FFF8E2;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
    padding:20px;

}

.selected_departure h1{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: inline-block;
    text-align: left;
}

.selected_departure a {
    color: #FFBA00;
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    float:right;
    text-align: right;
    display: inline-block;
}

.selected_departure h5 {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.selected_departure h6 {
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
}

@media screen and (max-width: 547px) {
    .selected_departure h1{
        font-size: 15px;
    }   
    
    .selected_departure h6 {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .selected_departure h5 {
        font-size: 14px;
    }
}