.upcoming-booking-container {
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.background-blue {
    border-radius: 3px 3px 0 0;
    background: linear-gradient(90deg, #006CB7 0%, #003A81 100%);
    height: 70px;  
    line-height: 200px;
}

.background-blue h1{
    color: white;
    font-family: "Gotham Rounded";
    font-weight: bold;
    /* vertical-align: middle; */
    line-height: 20px;
    text-align: left;
    font-size: 24px;
    padding: 25px;
}



.bg-yellow {
    border-radius: 3px 3px 0 0;
    background: linear-gradient(90deg, #FFF200 0%, #F2E200 100%);
    height: 70px;  
    line-height: 200px;
}

.bg-yellow h1{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-weight: bold;
    /* vertical-align: middle; */
    line-height: 20px;
    font-size: 20px;
    padding: 25px;
}

.text-right{
	text-align: right;
}



.upcoming-booking-container h5 {
    margin-top: 15px;
    margin-bottom: 10px;
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
}

.upcoming-booking-container p {
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    margin-top: 15px;
}

.upcoming-booking-container a {
    color: #FFBA00;
    display:block;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
}

.row-height {
    min-height:40px;
}

.row-min-height {
    /* min-height:40px; */
    /* margin-top:15px; */
    padding:15px;
}

.clear-margin {
    margin-left:0!important;
    margin-right:0!important;
}

.table-container {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
  
}

.background-color-change {
    background-color: #F4F9FF;
    /* box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1); */
}

@media screen and (max-width:575px) {
    .background-blue h1{
        font-size: 20px;
    }
	.bg-yellow h1{
        font-size: 18px;
    }
    .upcoming-booking-container p {
        font-size: 10px;
        font-weight: 100;
    }
    
    .upcoming-booking-container a {
        font-size: 10px;
    }
}