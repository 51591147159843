.MoreDetails h2 {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0 10px 15px;
    cursor: pointer;
}

.MoreDetails h2:hover {
    background-color: #2c5bf3;
    color:#fff;
}

.MoreDetails .MoreDetails_Nav {
    border-right: 1px solid #DDDDDF;
    /* height: 100%; */
    min-height: 80vh;
    padding: 15px 0 15px 15px;
    background-color: #E9EAEE;

}

.MoreDetails .selected {
    background: #00589f;
    border-left: 5px solid #00589f;
    color: white;
    border-radius: 5px;
}

.no-box-sizing {
    box-sizing: unset;
}

.MoreDetails_Content {
    margin-top:20px;
}

.MoreDetails_Content .departInfo {
    font-size: 12px;
    margin-top:10px;
}

.MoreDetails_Content .departInfo th{
    background: linear-gradient(90deg,#006cb7,#003a81);
    padding-left:10px;
    color:#fff;
}

.MoreDetails_Content .departInfo td{
    padding-left:10px;
}

.MoreDetails_Content .departInfo tr{
    height:40px;
    border-top: 1px solid #E7E7E7;
}

.gmap_canvas iframe {
    width:100%;
    height:200px;
}

.photogallery img {
    width: 100%;
    padding: 10px 0px;
}

@media screen and (max-width:768px){
    .MoreDetails .MoreDetails_Nav {
        min-height: 0vh;
        display:flex;
        padding:0;
    }

    .MoreDetails .MoreDetails_Nav h2{
        width: 50%;
        text-align: center;
        padding:10px 0px 10px 10px;
        margin-bottom:0;
        border-bottom:3px solid #9E9E9E;
    }

    .MoreDetails .selected {
        border-left:none;
        border-radius: 0;
        border-bottom: 3px solid #00589f!important;
    }
}