.loyalty-container {
    border-bottom: 1px solid #E7E7E7;
    border-radius: 3px;
}
.loyalty-container .VoucherApply{
    width:50%;
    float:right;
    margin-top:10px;
    background-color: #003A81;
    color:#fff;
    border-radius: 25px;
    padding:5px;
}
.loyalty-container .content_right{
    justify-content: right;
}

.loyalty_discount {
    margin-top: 20px;
}

#invalidCode, #invalidCurrency{
	margin-top: 8px;
    font-size: 12px;
    color:red;
}

.oval {
    height: 20px;
    width: 20px;
    background-color: #0068B2;
    border-radius: 50%;
    text-align: center;
    color:white;
    display: inline-block;
}

.oval_red {
    height: 20px;
    width: 20px;
    background-color: #FF0000;
    border-radius: 50%;
    text-align: center;
    color:white;
    display: inline-block;
    /* float:left; */
}

.loyalty_discount h1 {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    display: inline-block;
    padding-right:5px;
}

.loyalty_discount h5 {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 14px;
}

.loyalty_discount h5, .loyalty_discount h6 {
    text-align: right;
    padding: 8px 50px 0px 0px!important;
    line-height: 0;
}

.loyalty_discount select {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
}

.loyalty_form {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #003A81;
    border-radius: 3px;
    color: rgba(0,0,0,0.6);
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-indent: 0px!important;
}

.loyalty_form hr {
    color: #E7E7E7;
    border-radius: 3px;
    background-color: #E7E7E7;
    box-shadow: 0 3px 4px 1px rgba(0,58,129,0.1);
}

.loyalty_form option{
    color: rgba(0,0,0,0.8);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    
}

.loyalty_form option:disabled{
    color: rgba(0,0,0,0.4);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}

.loyalty_form select {
    height: 34px;
    width: 100%;
}

.loyalty_form .option_label {
    color: rgba(0,0,0,0.6)!important;
    font-family: "Gotham Rounded";
    font-size: 10px!important;
    font-weight: 300!important;
    letter-spacing: 0;
    line-height: 12px!important;
}







	.voucher-suggestions {
		border: 1px solid #ccc;
		max-height: 150px;
		overflow-y: auto;
		position: absolute;
		background-color: white;
		z-index: 1000;
		list-style-type: none;
		padding: 0;
		margin-top: 40px; /* Provides space between the input and dropdown */
		/* Matches the width of the input field */
		/* width: 25%;  */

		/* Aligns the dropdown with the input field */
		/* left: 0;  */
	}


	/* Mobile view */
	@media (max-width: 768px) {
		.voucher-suggestions {
			width: 100%; /* Full width in mobile */
			left: 0; /* Align to the left of the screen */
		}
	}

	/* Web view */
	@media (min-width: 769px) {
		.voucher-suggestions {
			width: 25%; /* 25% width in web view */
			/* left: 0; Align it as needed */
		}
	}


  
	.voucher-suggestions li {
		padding: 10px;
		cursor: pointer;
	}

	.voucher-suggestions li:hover {
		background-color: #f0f0f0;
	}




	.half-circle-border {
		position: relative;
		padding-left: 10px;
		/* Space for the half-circles */
		border-left: 1px solid #FFBA00;
		/* height: 110px; */
		/* width: 100%; */
		/* Adjusted width */
		background-color: #FFBA00;
		box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.5);
	}

	/* Wrapper div to center the content using Flexbox */
	.content-wrapper {
		display: flex;
		align-items: stretch;
		/* Ensure both items have equal height */
		justify-content: center;
		height: 100%;
	}

	.content {
		color: #FFFFFF;
		font-size: 22px;
		text-align: center;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		/* Stack items vertically */
		align-items: center;
		/* Center items horizontally */
		width: 125px;
		/*            border: solid;*/
		margin-top: 3%;
		padding-bottom: 3%;
	}

	.content div {
		text-shadow: 2px 2px 5px rgba(128, 128, 128, 0.5);
		/* Text shadow effect */
	}


	.voucher-description {
		background-color: white;
		color: #090909;
		font-size: 20px;
		padding: 5px 10px;
		display: flex;
		flex-direction: column;
		/* Stack items vertically */
		/* Vertically center voucher description text */

		/* Gray drop shadow */
		width: 210px;
		padding-top: 3%;
		padding-bottom: 3%;
		font-weight: normal;
	}

	/* General style for all half-circles */
	.half-circle {
		position: absolute;
		left: -26px;
		/* Align with left border */
		width: 30px;
		height: 15px;
		border-right: none;
		border-radius: 0px 20px 20px 0px;
		/* Half-circle shape */
		background-color: white;
		/* Matches the background */
	}

	/* Position the half-circles using nth-child */
	.half-circle:nth-child(1) {
		top: 5%;
	}

	.half-circle:nth-child(2) {
		top: 32%;
	}

	.half-circle:nth-child(3) {
		top: 55%;
	}

	.half-circle:nth-child(4) {
		top: 78%;
	}