.table-container h3 {
    color: white;
    font-family: "Gotham Rounded";
    font-weight: bold;
    /* vertical-align: middle; */
    line-height: 20px;
    text-align: right;
    font-size: 24px;
    padding: 25px;
}

.table-container img {
    padding: 0px 20px 7px 20px;
}

.background-yellow {
    background: #FFCC01;
    height: 5px;  
    line-height: 200px;
}

.date_items_center {
    align-items: center;
}

.pick_date_box {
    padding:20px;
    cursor: pointer;
}

.date_inactive {
    padding:20px;
    cursor:not-allowed;
}

.pick_date_box h4{
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 17px;
}

.pick_date_box h5{
    color: rgba(0,0,0,0.6);
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    line-height: 14px;
    display: block;
}

.pick_date_box h6{
    color: #FF0000;
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    line-height: 12px;
}

.date_selected {
    background-color: rgba(0,58,129,0.1);
}

.date_inactive h4{
    color: rgba(0,0,0,0.2);
}

.date_inactive h5{
    color: rgba(0,0,0,0.2);
}

.date_inactive h6{
    display:none;
}

.pick_departure {
    box-shadow: 0px 3px 4px 1px rgba(0,58,129,0.1);
    padding-bottom: 30px;
}

.pick_departure a{
    color: #003A81!important;
    font-family: "Gotham Rounded";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: underline!important;
}

.pick_departure h2{
    color: #003A81;
    font-family: "Gotham Rounded";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 2px;
    margin-top: 15px;
    text-decoration: underline;
}

.pick_departure h6{
    font-size: 10px;
    cursor: pointer;
}

.pick_departure .disclaimer {
    font-size: 10px;
    color: rgba(0,0,0,.6);
    background-color: #FFDB68;
    padding: 15px;
    border-radius: 10px;
}

.pick_departure .center {
    text-align: center;
}

.pick_departure .selected {
    background-color: #003A81!important;
}


.depart-data {
    margin-bottom: 17px;
    padding-top: 7px;
    border-radius: 3px;
    border-top: 1px solid rgba(0,58,129,0.1);
  
}

.margin-top {
    margin-top: 15px!important;
}

.trips_button {
    color: white!important;
    background-color: #FFBA00!important;
    /* height: 40px; */
    height: 30px;
    width: auto;
    /* padding: 8px 20px!important; */
    float:right;
    margin-top:8px;
    font-size:12px;
}

.date_select_container img {
    cursor: pointer;
}

.class {
    cursor: pointer;
}

.hidden {
    display:none!important;
}

.hover_item {
    border: 1px solid #003A81;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 2px 8px 10px 10px rgba(0,58,129,0.03);
    padding:10px;
    /* position:relative;
    display:table; */

    position: absolute; /* Changed from relative */
    /* display: none;  */
    /* Initially hidden */
    z-index: 10; /* Ensure it pops above other elements */

}

.hover_item h6{
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}

.hover_item .small_size {
    height:87px;
    width: auto;
}

.eta {
    color: #0068B2;
    font-family: "Gotham Rounded";
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
}

.no_bus_trip {
    text-align: center;
    padding:30px;
}

.no_bus_trip h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

.no_bus_trip h6 {
    font-size: 15px;
}


.sm-w-6{
	width: 60px; /* Default width */
}

.sm-mt-1{
	margin-top: 10px;
}
.sm-mt-2{
	margin-top: 20px;
}



@media screen and (max-width: 768px) {
    .hidden-768 {
        display: none;
    }
    
    .pick_departure h5, .pick_departure a, .pick_departure h2, .pick_departure p {
        font-size: 8px;
        text-align: center;
    }

    .trips_button {
        font-size: 10px;
        padding: 0px 10px !important;
        height: 25px;
        margin-top: 10px !important;
    }

    .pick_date_box {
        padding:12px;
    }

    .pick_departure h6 {
        font-size: 12px;
    }

    .pick_departure .disclaimer {
        font-size: 8px;
        color: rgba(0,0,0,.6);
        background-color: #FFDB68;
        padding: 15px;
        border-radius: 10px;
    }


    .departReturn_header h1, .departReturn_header h3, .departReturn_header img {
        font-size: 12px;
        height: 15px;
    }

    .info h6 {
        text-align: center;
    }


	
}

@media screen and (max-width: 575px) {
    .trips_button {
        float: none;
        font-size: 15px;
        padding: 0px 15px !important;
        height: 28px;
    }
    .departReturn_header h3 {
        font-size: 16px;
    }
    .pick_departure h2 {
        text-decoration: none;
        margin: 0;
    }
    .pick_departure .display-on-mobile .class {
        white-space: nowrap;
        max-width: 100%;
        font-size: 11px;
    }
    .pick_departure .display-on-mobile span{
        color: black;
        font-family: "Gotham Rounded";
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        margin: 0;
        padding-left: 5px;
        cursor: pointer;
    }
    .display-on-mobile.info {
        display: flex;
        align-items: end;
        justify-content: start;
        padding-left: 30px;
    }
    .display-on-mobile.moredetails {
        display: flex;
        align-items: end;
        justify-content: left;
    }
    .pick_departure h5, .pick_departure a,  .pick_departure p {
        font-size: 13px;
        font-weight: 100;
    }
    .pick_departure a {
        text-align: left;
    }
    .pick_departure .mobile-left-align {
        text-align: left;
        padding-left: 20px;
    }


	.xs-w-3{
		width: 30px;
	}
	.xs-w-4{
		width: 40px;
	}

	.xs-mt-1{
		margin-top: 10px;
	}
	.xs-mt-2{
		margin-top: 20px;
	}
	
}

@media screen and (max-width: 547px) {
    .hidden-547 {
        display:none;
    }

    .pick_departure .disclaimer {
        font-size: 6px;
        color: rgba(0,0,0,.6);
        background-color: #FFDB68;
        padding: 5px;
        border-radius: 10px;
        line-height: normal;
        text-align: left;
    }
}

@media screen and (max-width: 450px) {
    .display-on-mobile.info {
        padding-left: 20px;
    }
    .pick_departure .mobile-left-align {
        padding-left: 8px;
    }
    .display-on-mobile.moredetails {
        justify-content: center;
        font-size: 10px;
    }
}